<template>
  <div v-if="!currentUser.adminByNow" class="box error">
    <h2 class="mb-3">
      {{ $t('access-denied') }}
    </h2>
    <p>{{ $t('insufficient-permission') }}</p>
  </div>
  <div v-else class="content-wrapper">
    <BField class="radio-buttons-fullwidth">
      <BRadioButton v-model="activeTab" native-value="dashboard" type="is-link">
        {{ $t('dashboard') }}
      </BRadioButton>

      <BRadioButton v-model="activeTab" native-value="users" type="is-link">
        {{ $t('users') }}
      </BRadioButton>

      <BRadioButton
        v-model="activeTab"
        native-value="trusted-sources"
        type="is-link"
      >
        {{ $t('trusted-sources') }}
      </BRadioButton>

      <!-- Tags hidden until scoped access per user per project -->
      <!-- <BRadioButton v-model="activeTab" native-value="tags" type="is-link">
        {{ $t('tags') }}
      </BRadioButton> -->

      <BRadioButton
        v-model="activeTab"
        native-value="configuration"
        type="is-link"
      >
        {{ $t('configuration') }}
      </BRadioButton>
    </BField>

    <div class="box">
      <keep-alive>
        <component :is="activeComponent" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import AdminDashboard from './AdminDashboard';
import AdminUsers from './AdminUsers';
import AdminSoftware from './AdminSoftware';
import Configuration from './AdminConfiguration';
import AdminTags from './AdminTags';

const defaultTab = 'dashboard';

export default {
  name: 'AdminPanel',
  data() {
    return {
      activeTab: 0,
      tabNames: [
        'dashboard',
        'users',
        'trusted-sources',
        'tags',
        'configuration',
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    queriedTab() {
      return this.$route.query.tab;
    },
    activeComponent() {
      switch (this.activeTab) {
        case 'dashboard':
          return AdminDashboard;
        case 'users':
          return AdminUsers;
        case 'configuration':
          return AdminConfiguration;
        case 'trusted-sources':
          return AdminSoftware;
        case 'tags':
          return AdminTags;
        default:
          return '';
      }
    },
  },
  watch: {
    queriedTab() {
      this.changeTab();
    },
    activeTab() {
      if (this.activeTab !== defaultTab || this.queriedTab) {
        this.$router.push(`?tab=${this.activeTab}`);
      }
    },
  },
  created() {
    this.changeTab();
  },
  methods: {
    changeTab() {
      this.activeTab = this.queriedTab || defaultTab;
      if (!this.activeComponent) {
        this.activeTab = defaultTab;
      }
    },
  },
};
</script>

<style scoped>
.box {
  position: relative;
  min-height: 20em;
}
</style>
